/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import 'moment/locale/ko';

import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter';
import { t } from 'i18next';

class Register extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      username: "",
      password: "",
      email: "",
      errDisplay: "none",
      errMessage: "",
      userNameInputStyle: "input-group-alternative mb-3",
      emailInputStyle: "input-group-alternative mb-3"
    }
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  };

  onSubmitValid = (event) => {
    event.preventDefault();
    this.setState({ userNameInputStyle: "input-group-alternative mb-3" });
    this.setState({ emailInputStyle: "input-group-alternative mb-3" });

    const passwordInput = document.getElementById("password");
    const regex = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%^&*+=]).*$/gm;

    if (!regex.test(this.state.password)) {
      passwordInput.setCustomValidity(t('registerForm.passwordInvalidError'));
      passwordInput.reportValidity();
    } else {
      passwordInput.setCustomValidity('');
      const submitPrep = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: this.state.name,
          username: this.state.username,
          email: this.state.email,
          password: this.state.password
        })
      };

      fetch(process.env.REACT_APP_API_HOST + '/users', submitPrep)
        .then(response => response.json())
        .then(data => {

          if (data.ok) {
            const submitPrep = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
              })
            };

            fetch(process.env.REACT_APP_API_HOST + '/auth/login', submitPrep)
              .then(response => response.json())
              .then(data => {
                if (data.ok) {

                  localStorage.setItem('token', data.access_token);
                  localStorage.setItem('isLoggedIn', true);
                  localStorage.setItem('username', this.state.username);

                  window.location.href = "/";
                  window.history.replaceState({}, '');
                } else {
                  console.log(data);
                }
              })
              .catch(err => console.log(err));
          } else {
            this.setState({ errDisplay: "inline" });

            if (data.error === "User already exists") {
              this.setState({ errMessage: "registerForm.userExistedError" });
              this.setState({
                userNameInputStyle: "border border-danger input-group-alternative mb-3"
              });
            }

            if (data.error === "Email already exists") {
              this.setState({ errMessage: "registerForm.emailExistedError" });
              this.setState({
                emailInputStyle: "border border-danger input-group-alternative mb-3"
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-0">
                      <div className="text-muted text-center mb-3">
                        {t('register')}
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form" onSubmit={this.onSubmitValid}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('registerForm.name')}
                              type="text"
                              name="name"
                              required={true}
                              value={this.state.name}
                              onChange={e => this.setState({ name: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className={this.state.userNameInputStyle}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('baseForm.username')}
                              type="text"
                              name="username"
                              required={true}
                              value={this.state.username}
                              onChange={e => this.setState({ username: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className={this.state.emailInputStyle}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('registerForm.email')}
                              type="email"
                              name="email"
                              required={true}
                              value={this.state.email}
                              onChange={e => this.setState({ email: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('baseForm.password')}
                              type="password"
                              autoComplete="off"
                              name="password"
                              id='password'
                              required={true}
                              value={this.state.password}
                              onChange={e => {
                                this.setState({ password: e.target.value });

                                e.target.setCustomValidity('');
                              }
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic" style={{ display: this.state.errDisplay }}>
                          <small className='text-danger font-weight-700'>
                            {t(this.state.errMessage)}
                          </small>
                        </div>

                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="primary"
                            type="submit"
                          >
                            {t('registerButtonLabel')}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default withTranslation()(Register);
