/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Col, Container, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';

class CardsFooter extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <footer className="footer">
          {/* <Container className="container-lg">
            <Row>
              <Col className="mb-5 mb-md-0" md="6">
                <Card className="card-lift--hover shadow border-0">
                  <Link to="/landing-page">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/landing.jpg")}
                    />
                  </Link>
                </Card>
              </Col>
              <Col className="mb-5 mb-lg-0" md="6">
                <Card className="card-lift--hover shadow border-0">
                  <Link to="/profile-page">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/profile.jpg")}
                    />
                  </Link>
                </Card>
              </Col>
            </Row>
          </Container> */}
          <Container style={{ maxWidth: '425px' }}>
            {/* <Row className="row-grid align-items-center my-md">
              <Col lg="6">
                <h6 className="mb-0 font-weight-light">충북 청주시 서원구 충대로 1, 학연산공동기술연구원 509호</h6>
                <h6 className="mb-0 font-weight-light">TEL: 043-266-8778</h6>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/creativetim"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/creativetim"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="dribbble"
                  href="https://dribbble.com/creativetim"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-dribbble" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href="https://github.com/creativetimofficial"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-github" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Github
                </UncontrolledTooltip>
              </Col>
            </Row> */}
            <hr style={{ visibility: 'hidden' }} />
            <Row className="align-items-center justify-content-md-between">
              <Col md="12">
                <div>{t('footer.addressTitle')}</div>
                <div>{t('footer.address')}</div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-md-between">
              &nbsp;
            </Row>
            <Row className="align-items-center justify-content-md-between">
              <Col md="6" className="">
                <div>{t('footer.contactTitle')}</div>
                <div>{t('footer.contact')}</div>
              </Col>
              <Col md="6">
                <div>{t('footer.emailTitle')}</div>
                <div>{t('footer.email')}</div>
              </Col>
            </Row>
            {/* <Row className="align-items-center justify-content-md-between">
              &nbsp;
            </Row>
            <Row className="align-items-center justify-content-md-between">
              <Col md="12">
                <div>
                  Copyright
                  © {new Date().getFullYear()}{" "}
                  바디웰 All Rights Reserved.
                </div>
              </Col>
            </Row> */}
            <hr style={{ visibility: 'hidden' }} />
          </Container>
        </footer>
      </>
    );
  }
}

export default withTranslation()(CardsFooter);
