/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: window.history?.state?.username,
      password: window.history?.state?.password,
      message: window.history?.state?.message ? 'loginForm.registerComplete' : '',
      errDisplay: "none",
      errMessage: "",
      userNameInputStyle: "input-group-alternative",
      passwordInputStyle: "input-group-alternative"
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  onSubmitValid = (event) => {
    event.preventDefault();
    this.setState({
      userNameInputStyle: "input-group-alternative",
      passwordInputStyle: "input-group-alternative"
    });

    const submitPrep = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password
      })
    };

    fetch(process.env.REACT_APP_API_HOST + '/auth/login', submitPrep)
      .then(response => response.json())
      .then(data => {
        if (data.ok) {

          localStorage.setItem('token', data.access_token);
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('username', this.state.username);

          window.location.href = "/";
          window.history.replaceState({}, '');
        } else {
          this.setState({ errDisplay: "inline" });

          if (data.error === 'User not found.') {
            this.setState({ errMessage: 'loginForm.errorUserNotFound' });
            this.setState({
              userNameInputStyle: "border border-danger input-group-alternative"
            });
          }

          if (data.error === 'Wrong password') {
            this.setState({ errMessage: 'loginForm.errorWrongPassword' });
            this.setState({
              passwordInputStyle: "border border-danger input-group-alternative"
            });
          }
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-0">
                      <div className="text-muted text-center mb-3">
                        {t('login')}
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form" onSubmit={this.onSubmitValid}>
                        <FormGroup className="mb-3">
                          <div className="text-success text-left mb-3">
                            <small>{t(this.state.message)}</small>
                          </div>
                          <InputGroup className={this.state.userNameInputStyle}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('baseForm.username')}
                              type="text"
                              name='username'
                              required={true}
                              value={this.state.username ? this.state.username : ''}
                              onChange={e => this.setState({ username: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className={this.state.passwordInputStyle}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('baseForm.password')}
                              type="password"
                              autoComplete="off"
                              name='password'
                              required={true}
                              value={this.state.password ? this.state.password : ''}
                              onChange={e => this.setState({ password: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic" style={{ display: this.state.errDisplay }}>
                          <small className='text-danger font-weight-700'>
                            {t(this.state.errMessage)}
                          </small>
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                          >
                            {t('loginButtonLabel')}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                    </Col>
                    <Col className="text-right" xs="6">
                      <a
                        className="text-light"
                        href="/register"
                      >
                        <small>{t('createAccount')}</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default withTranslation()(Login);
