/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
// import classnames from "classnames";
// reactstrap components
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter.js';

import { withTranslation } from 'react-i18next';

// index page sections
// import Download from "../IndexSections/Download.js";

// style
const workBadge = {
  padding: '1rem 1.5rem',
  fontSize: '100%',
  borderRadius: '3rem',
  margin: '0.75rem 0.25rem',
  whiteSpace: 'inherit',
};
const workImage = {
  width: '90%',
  height: '15rem',
  objectFit: 'cover',
  border: '5px solid #ffffff',
  borderRadius: '1rem',
};
const wordBreakKeepAll = { wordBreak: 'keep-all' };
const em = { fontWeight: '600', fontStyle: 'normal', fontSize: '1.80rem' };
const topButtonSuccess = {
  borderRadius: '0 0 0.25rem 0.25rem',
  fontSize: '1.25rem',
  borderTop: '5px solid #1cae6f',
};
const topButtonWarning = {
  borderRadius: '0 0 0.25rem 0.25rem',
  fontSize: '1.25rem',
  borderTop: '5px solid #ff3708',
};
const topButtonPrimary = {
  borderRadius: '0 0 0.25rem 0.25rem',
  fontSize: '1.25rem',
  borderTop: '5px solid #2743e9',
};
const sectionTitle = { fontWeight: '900' };
const aboutusTitle = { fontWeight: '900', color: '#485735' };

// const aboutusImage = { background: 'url("' + require("assets/img/brand/aboutus-1.png") + '") 5rem 3rem / cover no-repeat' }

class Landing extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default"></div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="8">
                      <h1
                        className="display-3 text-white"
                        style={wordBreakKeepAll}
                      >
                        <span style={{ marginTop: 60 }}>
                          <em style={em}>{t('mainSection.title1')}</em>
                        </span>
                        {t('mainSection.title2')}{' '}
                        <em style={em}>{t('mainSection.title3')}</em>
                      </h1>
                      <br />
                      <p className="lead text-white" >
                        {t('mainSection.content1')}
                      </p>
                      <p className="lead text-white" style={{ marginTop: -20 }}>
                        {t('mainSection.content2')}
                      </p>
                      <p className="lead text-white" style={{ marginTop: -20 }}>
                        {t('mainSection.content3')}
                      </p>
                      <p className="lead text-white" style={{ marginTop: -20 }}>
                        {t('mainSection.content4')}
                      </p>
                      {t('mainSection.content5') === '<br />' ? (
                        <br />
                      ) : (
                        <p className="lead text-white" style={{ marginTop: -20 }}>
                          {t('mainSection.content5')}
                        </p>
                      )}
                      <br />
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-sm pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 text-center">
                        <CardBody>
                          <div className="icon icon-shape icon-shape-success rounded-circle">
                            <i className="fa fa-users" aria-hidden="true" />
                          </div>
                          <h5 className="mt-4">
                            {t('mainSection.box1.content1')}
                            <br />
                            {t('mainSection.box1.content2')}
                            <br />
                            {t('mainSection.box1.content3')}
                            <br />
                            {t('mainSection.box1.content4') === '<br />' ? (
                              <br />
                            ) : (
                              t('mainSection.box1.content4')
                            )}
                          </h5>
                        </CardBody>
                        <Button
                          block
                          color="success"
                          size="lg"
                          type="button"
                          style={topButtonSuccess}
                        >
                          <strong>{t('mainSection.box1.title')}</strong>
                        </Button>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 text-center">
                        <CardBody>
                          <div className="icon icon-shape icon-shape-warning rounded-circle">
                            <i className="fa fa-heartbeat" aria-hidden="true" />
                          </div>
                          <h5 className="mt-4">
                            {t('mainSection.box2.content1')}
                            <br />
                            {t('mainSection.box2.content2')}
                            <br />
                            {t('mainSection.box2.content3')}
                            <br />
                            {t('mainSection.box2.content4') === '<br />' ? (
                              <br />
                            ) : (
                              t('mainSection.box2.content4')
                            )}
                          </h5>
                        </CardBody>
                        <Button
                          block
                          color="warning"
                          size="lg"
                          type="button"
                          style={topButtonWarning}
                        >
                          <strong>{t('mainSection.box2.title')}</strong>
                        </Button>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 text-center">
                        <CardBody>
                          <div className="icon icon-shape icon-shape-primary rounded-circle">
                            <i
                              className="fa fa-lightbulb-o"
                              aria-hidden="true"
                            />
                          </div>
                          <h5 className="mt-4">
                            {t('mainSection.box3.content1')}
                            <br />
                            {t('mainSection.box3.content2')}
                            <br />
                            {t('mainSection.box3.content3')}
                            <br />
                            {t('mainSection.box3.content4') === '<br />' ? (
                              <br />
                            ) : (
                              t('mainSection.box3.content4')
                            )}
                          </h5>
                        </CardBody>
                        <Button
                          block
                          color="primary"
                          size="lg"
                          type="button"
                          style={topButtonPrimary}
                        >
                          <strong>{t('mainSection.box3.title')}</strong>
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-sm">
            <Container className="pt-lg pb-lg">
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <h1 className="display-1" style={aboutusTitle}>
                    ABOUT US
                  </h1>
                  <img
                    alt="..."
                    className="img-fluid mt-2 mb-2"
                    src={require('assets/img/brand/aboutus-temp-' +
                      t('aboutUsSection.img') +
                      '.png')}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-sm bg-secondary">
            <Container className="pt-lg pb-lg">
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <h1 className="display-1" style={sectionTitle}>
                    WORK
                  </h1>
                  <img
                    alt="..."
                    className="img-fluid mt-2 mb-2"
                    src={require('assets/img/brand/work-0.png')}
                  />
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4" style={{ textAlign: 'center' }}>
                  <img
                    alt="..."
                    className="img-fluid shadow"
                    src={require('assets/img/brand/work-1.jpg')}
                    style={workImage}
                  />
                </Col>
                <Col lg="4" style={{ textAlign: 'center' }}>
                  <img
                    alt="..."
                    className="img-fluid shadow"
                    src={require('assets/img/brand/work-2.jpg')}
                    style={workImage}
                  />
                </Col>
                <Col lg="4" style={{ textAlign: 'center' }}>
                  <img
                    alt="..."
                    className="img-fluid shadow"
                    src={require('assets/img/brand/work-3.jpg')}
                    style={workImage}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className="section section-sm"
            style={{
              background:
                'url("' +
                require('../../assets/img/brand/solution-bg-1.jpg') +
                '")',
            }}
          >
            <Container className="pt-lg pb-lg">
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <h1 className="display-1 text-white" style={sectionTitle}>
                    SOLUTION
                  </h1>
                  <a
                    href="#"
                    className="badge badge-secondary shadow solutionBadage"
                    style={workBadge}
                  >
                    {t('solutionSection.title')}
                  </a>
                  <h4 className="text-white mt-5">
                    {t('solutionSection.content1')}
                    <br />
                    {t('solutionSection.content2')}
                    <br />
                    {t('solutionSection.content3') === '<br />' ? (
                      <br />
                    ) : (
                      t('solutionSection.content3')
                    )}
                  </h4>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-sm">
            <Container className="pt-sm pb-lg">
              <Row className="text-center justify-content-center">
                <Col lg="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require('assets/img/brand/wearable-1.jpg')}
                  />
                </Col>
              </Row>
              <Row className="text-center justify-content-center">
                <Col lg="6" className="mt-4">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require('assets/img/brand/logo-color.png')}
                  />
                </Col>
              </Row>
            </Container>
          </section>

        </main>
        <CardsFooter />
      </>
    );
  }
}

export default withTranslation()(Landing);
