/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import Moment from 'moment';
import 'moment/locale/ko';

import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter';
import Pagination from 'react-js-pagination';

// style
const mainSection1 = { height: '350px' };

class Notice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noticeList: [],
      totalRecord: 0,
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      searchKeyword: '',
      isAll: true,
      isLoggIn: localStorage.getItem('isLoggedIn'),
      isMine: false,
      myId: '',
      isAdmin: '',
    };
  }

  getUserInfo = () => {

    if (localStorage.getItem('username')) {

      fetch(process.env.REACT_APP_API_HOST +
        "/users/myProfile?username=" +
        localStorage.getItem('username'
        ), {
        method: "GET",
        headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') }
      })
        .then(response => response.json())
        .then(data => {
          this.setState({
            isAdmin: data.users[0].role,
          })
        })
        .catch(err => console.log(err));
    }
  };

  fetchData = (pageNumber, all = true, keyword = '') => {
    const keywordParam = keyword ? `&keyword=${keyword}` : '';

    let url = process.env.REACT_APP_API_HOST + `/notice?page=${pageNumber}${keywordParam}`;
    let requestInit = { method: 'GET' };

    if (!all) {
      url = process.env.REACT_APP_API_HOST + `/notice/mine?page=${pageNumber}${keywordParam}`;
      requestInit = {
        method: 'GET',
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        }
      };
    };

    fetch(url, requestInit)
      .then(response => response.json())
      .then((data) => {

        if (data.ok) {
          this.setState({
            noticeList: data.notices,
            totalRecord: data.totalRecord,
            itemsCountPerPage: 1,
            totalItemsCount: data.totalRecord / 10,
            pageRangeDisplayed: data.totalRecord > 30 ? 3 : 1,
          });
        } else {
          this.setState({
            noticeList: {},
            totalRecord: 0,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            pageRangeDisplayed: 1,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.fetchData(pageNumber, this.state.isAll, this.state.searchKeyword);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    this.fetchData(1);
    this.getUserInfo();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section
            className="section-profile-cover section-shaped my-0"
            style={mainSection1}
          >
            <div className="shape shape-style-1 shape-default alpha-4"></div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <div className="text-center mt-5 mb-5">
                  </div>
                  <Card className="my-2">
                    <CardHeader tag="h5" className="text-center">
                      {t('notice')}
                    </CardHeader>
                    <CardHeader
                      className="bg-white border-0"
                      style={{ display: this.state.isAdmin === 'Admin' ? 'inline' : 'none' }}
                    >
                      <Row className="float-right">
                        <Col xs="1.5" style={{ display: "none" }}>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="checkBoxAll"
                              type="checkbox"
                              checked={this.state.isAll}
                              onChange={(e) => {
                                this.setState({
                                  isAll: true,
                                  activePage: 1,
                                  searchKeyword: '',
                                });
                                this.fetchData(1);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkBoxAll"
                            >
                              {t('all')}
                            </label>
                          </div>
                        </Col>
                        <Col style={{ display: "none" }}>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="checkBoxMyPost"
                              type="checkbox"
                              checked={!this.state.isAll}
                              onChange={(e) => {
                                this.setState({
                                  isAll: false,
                                  activePage: 1,
                                  searchKeyword: '',
                                });
                                this.fetchData(1, false);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkBoxMyPost"
                            >
                              {t('myPost')}
                            </label>
                          </div>
                        </Col>
                        <Col xs="2.5">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              window.location.href = '/write'
                            }}
                          >
                            {t('createPost')}
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <ListGroup flush>
                      {
                        (this.state.noticeList?.length > 0) ?
                          (
                            this.state && this.state.noticeList
                          ).map(function (item, index) {
                            item.createdAt = Moment(item.createdAt).format(
                              'yyyy-MM-DD',
                            );
                            return (
                              <NoticeListGroupItem
                                key={index}
                                item={item}
                                isAll={this.state.isAll}
                              />
                            );
                          }, this) : null}
                    </ListGroup>
                  </Card>

                  <InputGroup className="mt-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('search')}
                      type="text"
                      value={this.state.searchKeyword}
                      onChange={(e) => {
                        this.setState({
                          searchKeyword: e.target.value,
                          activePage: 1
                        });
                      }}
                    />
                    <Button
                      /* search button */
                      color="primary"
                      type="button"
                      onClick={(e) => {
                        this.fetchData(1, this.state.isAll, this.state.searchKeyword)
                      }
                      }
                    >
                      {t('search')}
                    </Button>
                  </InputGroup>

                  <div className="py-5 text-center">
                    <Row className="justify-content-center">
                      <div>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={this.handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Row>
                    <Row className="justify-content-center"></Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

function NoticeListGroupItem(props) {
  const isMine = props.item.Author.username === localStorage.getItem('username');
  if (props.isAll) {
    return (
      <ListGroupItem
        action
        tag="button"
        onClick={() => {

          const targetNoticeUrl = `/notice/${props.item.id}`;
          window.history.pushState(
            {
              noticeId: props.item.id,
              title: props.item.title,
              content: props.item.content,
              published: props.item.published,
              views: props.item.views,
              isMine
            },
            "",
            targetNoticeUrl
          );
          window.location.href = targetNoticeUrl;
        }}
      >
        <CardTitle tag="h6" className="mb-0">
          {props.item.title}
        </CardTitle>
        <CardText>
          <small className="text-muted">
            {props.item.createdAt} | {props.item.Author.name}
          </small>
        </CardText>
      </ListGroupItem>
    )
  } else {
    if (isMine) {
      return (
        <ListGroupItem
          action
          tag="button"
          onClick={() => {

            const targetNoticeUrl = `/notice/${props.item.id}`;
            window.history.pushState(
              {
                noticeId: props.item.id,
                title: props.item.title,
                content: props.item.content,
                published: props.item.published,
                views: props.item.views,
                isMine
              },
              "",
              targetNoticeUrl
            );
            window.location.href = targetNoticeUrl;
          }}
        >
          <CardTitle tag="h6" className="mb-0">
            {props.item.title}
          </CardTitle>
          <CardText>
            <small className="text-muted">
              {props.item.createdAt} | {`By ${props.item.Author.name}`}
            </small>
          </CardText>
        </ListGroupItem>
      );
    }
  }

}

export default withTranslation()(Notice);
