/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import 'assets/scss/argon-design-system-react.scss?v1.1.0';

import Landing from 'views/examples/Landing.js';
import Login from 'views/examples/Login.js';
import Profile from 'views/examples/Profile.js';
import Register from 'views/examples/Register.js';
import Notice from 'views/examples/Notice.js';
import Detail from 'views/examples/Detail.js';
import NoticeWrite from 'views/examples/NoticeWrite.js';

// 다국어
import 'languages/i18n';
import i18next from 'i18next';
import './config';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (localStorage.getItem('lang') === 'en') {
  localStorage.setItem('lang', 'en');
  i18next.changeLanguage('en');
} else {
  localStorage.setItem('lang', 'ko');
  i18next.changeLanguage('ko');
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />

      {/* 공지사항 - 글 목록 */}
      <Route path="notice">
        <Route index={true} element={<Notice />} />

        {/* 공지사항 - 글 상세 */}
        <Route index={false} path=":id" element={<Detail />} />
      </Route>

      {/* 공지사항 - 글쓰기 */}
      <Route path="write" element={localStorage.getItem('isLoggedIn') ? <NoticeWrite /> : <Landing />} />

      <Route path="landing-page" element={<Landing />} />
      <Route path="login" element={!localStorage.getItem('isLoggedIn') ? <Login /> : <Navigate to="/" replace />} />
      <Route path="profile" element={localStorage.getItem('isLoggedIn') ? <Profile /> : <Navigate to="/" replace />} />
      <Route path="register" element={!localStorage.getItem('isLoggedIn') ? <Register /> : <Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>,
);