/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

// style
const mainSection1 = { height: '400px' };

class NoticeWrite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noticeId: '',
      title: '',
      writer: '',
      content: '',
      isShowAlert: false,
      isUpdateMode: false,
      isPublish: true,
      views: '0'
    };
  }

  showAlert = (isShow) => {
    this.setState({
      isShowAlert: isShow,
    });
  };

  onDecodeTheText = (contextVar) => {
    try {
      const result = contextVar ? decodeURIComponent(contextVar) : '';
      return result;
    } catch (error) {
      console.log({
        errorMessage: error,
        contextValue: contextVar
      })
    }
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.setState({
      writer: localStorage.getItem('username')
    });

    if (window.history?.state?.isUpdate === undefined) {
      this.setState({ isUpdateMode: false });
    } else {
      this.setState({
        isUpdateMode: window.history?.state.isUpdate,
        noticeId: window.history?.state?.noticeId,
        title: window.history?.state?.title,
        content: window.history?.state?.content,
        isPublish: window.history?.state?.published,
        views: window.history?.state?.views,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section
            className="section-profile-cover section-shaped my-0"
            style={mainSection1}
          >
            <div className="shape shape-style-1 shape-default alpha-4" />
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-5">
                  <Row>
                    <Col>
                      <h5 className="text-center pt-5">
                        {t('notice')} -{' '}
                        {this.state.isUpdateMode
                          ? t('updateButtonLabel')
                          : t('writeButtonLabel')}
                      </h5>
                    </Col>
                  </Row>

                  <div className="mt-4 py-5 border-top">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                        checked={this.state.isPublish}
                        onChange={(e) => {
                          this.setState({
                            isPublish: !this.state.isPublish,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        {t('publishCheckBoxLabel')}
                      </label>
                    </div>

                    <Form>
                      <FormGroup row>
                        <Label for="noticeTitle" sm={2}>
                          {t('boardForm.title')}
                        </Label>

                        <Col sm={10}>
                          <Input
                            id="noticeTitle"
                            name="title"
                            placeholder={t('boardForm.titlePlaceholder')}
                            type="text"
                            value={this.state.title}
                            onChange={(e) => {
                              this.setState({ title: e.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="noticeContent" sm={2}>
                          {t('boardForm.content')}
                        </Label>
                        <Col sm={10}>
                          <Input
                            id="noticeContent"
                            name="content"
                            placeholder={t('boardForm.contentPlaceholder')}
                            type="textarea"
                            rows="10"
                            value={this.onDecodeTheText(this.state.content)}
                            onChange={(e) => {
                              this.setState({ content: e.target.value.trim() ? encodeURIComponent(e.target.value) : '' });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup check row>
                        <NoticeButtons
                          data={this.state}
                          showAlert={this.showAlert}
                          isUpdateMode={this.state.isUpdateMode}
                          decodeComp={this.onDecodeTheText}
                        />
                      </FormGroup>

                      {this.state.isShowAlert ? (
                        <WriteAlert showAlert={this.showAlert} />
                      ) : (
                        ''
                      )}
                    </Form>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

function NoticeButtons(props) {
  const navigate = useNavigate();
  return (
    <>
      <Col className="text-center">
        <Button
          color="secondary"
          onClick={(e) => {
            navigate('/notice');
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          onClick={(e) => {

            const propsData = props.data;

            if (
              propsData.writer === '' ||
              propsData.title.trim() === '' ||
              propsData.content.trim() === '' ||
              propsData.isPublish === ''
            ) {
              return props.showAlert(true);
            }

            const rawBody = {
              title: propsData.title,
              content: props.decodeComp(propsData.content),
              views: String(propsData.views),
              published: String(propsData.isPublish),
              updateDate: new Date().toLocaleString()
            };

            // 수정
            if (props.isUpdateMode) {

              const submitPrep = {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(rawBody)
              };

              fetch(
                `${process.env.REACT_APP_API_HOST}/notice/${propsData.noticeId}`,
                submitPrep
              ).then(
                response => response.json()
              ).then(
                data => {
                  if (data.ok) {
                    window.location.href = '/notice';
                  }

                  if (data.message) {
                    console.log(data.message);
                    return;
                  }

                }
              ).catch(err => console.log(err));
            }
            // 쓰기
            else {

              const submitPrep = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(rawBody)
              };

              fetch(process.env.REACT_APP_API_HOST + '/notice', submitPrep)
                .then(response => response.json())
                .then(data => {

                  if (data.ok) {
                    window.location.href = '/notice';
                  }
                })
                .catch((err) => console.log(err));
            }
          }}
        >
          {props.isUpdateMode ? t('updateButtonLabel') : t('writeButtonLabel')}
        </Button>
      </Col>
    </>
  );
}

function WriteAlert(props) {
  return (
    <UncontrolledAlert
      className="mt-3"
      color="warning"
      onClick={(e) => {
        props.showAlert(false);
      }}
    >
      {t('boardForm.emptyMessage')}
    </UncontrolledAlert>
  );
}

export default withTranslation()(NoticeWrite);
