/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter';
import { t } from 'i18next';

const mainSection1 = { height: '350px' };
class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      editProfileButtonDisplay: "inline",
      confrimButtonDisplay: "none",
      inputDisable: true,
      name: "",
      email: "",
      firstName: "",
      username: "",
      password: "",
      role: "",
      userId: "",
      oldPassword: "",
      newPassword: "",
      oldUsername: "",
    }
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.onReadProfile();
  }

  onReadProfile = () => {
    const token = localStorage.getItem('token');

    if (token) {
      fetch(process.env.REACT_APP_API_HOST + "/auth/profile", {
        method: "GET",
        headers: { 'authorization': 'Bearer ' + token }
      })
        .then(response => response.json())
        .then(data => {

          fetch(process.env.REACT_APP_API_HOST + "/users/myProfile?username=" + data.username, {
            method: "GET",
            headers: { 'authorization': 'Bearer ' + token }
          })
            .then(response => response.json())
            .then(data => {
              this.setState({
                userId: data.users[0].id,
                name: data.users[0].name,
                email: data.users[0].email,
                username: data.users[0].username,
                role: data.users[0].role,
                oldUsername: data.users[0].username,
              })
            });
        })
        .catch(err => console.log(err));
    } else {
      console.log("No authorize to perform this action.");
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const submitPrep = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        name: this.state.name,
        username: this.state.username,
        email: this.state.email
      })
    };

    if (this.state.userId) {
      fetch(`${process.env.REACT_APP_API_HOST}/users/${this.state.userId}`, submitPrep)
        .then(respone => respone.json())
        .then(data => {
          if (data.ok) {
            if (this.state.username !== this.state.oldUsername) {
              alert(t('registerForm.reLoginNotice'));

              localStorage.removeItem('token');
              localStorage.removeItem('isLoggedIn');
              localStorage.removeItem('username');

              window.history.pushState({
                username: this.state.username
              },
                "",
                "/login",
              );

              window.location.href = "login";
            }

            this.setState({
              confrimButtonDisplay: "none",
              editProfileButtonDisplay: "inline",
              inputDisable: true
            });
          }
        })
        .catch(err => console.log(err));
    }
  }

  onChangePassword = (event) => {
    event.preventDefault();

    const passwordInput = document.getElementById("newPasswordInput");
    const regex = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%^&*+=]).*$/gm;

    if (!regex.test(this.state.newPassword)) {
      passwordInput.setCustomValidity(t('registerForm.passwordInvalidError'));
      passwordInput.reportValidity();
    } else {
      passwordInput.setCustomValidity('');

      const submitPrep = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify({
          password: this.state.newPassword
        })
      };
      if (this.state.userId) {
        fetch(`${process.env.REACT_APP_API_HOST}/users/password/${this.state.userId}?oldPassword=${this.state.oldPassword}`, submitPrep)
          .then(respone => respone.json())
          .then(data => {

            if (data.ok) {
              this.setState({
                oldPassword: '',
                newPassword: ''
              });

              window.location.href = '/profile';
            } else {
              if (data.error) {
                console.log(data.error);
                alert(t('changePasswordForm.oldPasswordInvalid'));
              };
            }
          })
          .catch(err => console.log(err));
      }
    }
  }

  onDelete = () => {
    const submitPrep = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    if (this.state.userId) {
      fetch(
        `${process.env.REACT_APP_API_HOST}/users/${this.state.userId}`,
        submitPrep
      )
        .then(respone => respone.json())
        .then(data => {
          if (data.ok) {
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('username');

            window.location.href = "/";
          };
        })
        .catch(err => console.log(err));
    }
  }

  ChangePasswordModal = (args) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
      <span>
        <Button
          color="primary"
          onClick={toggle}
          size="sm"
          style={{ display: this.state.editProfileButtonDisplay }}
        >
          {t('changePasswordButtonLabel')}
        </Button>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>{t('changePasswordButtonLabel')}</ModalHeader>
          <ModalBody>
            <Form id="passwordForm" onSubmit={this.onChangePassword}>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="11">
                    <FormGroup>
                      <label className="form-control-label">
                        {t('changePasswordForm.oldPassword')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        name="oldPassword"
                        value={this.state.oldPassword}
                        required={true}
                        type="password"
                        onChange={e => this.setState({ oldPassword: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="11">
                    <FormGroup>
                      <label className="form-control-label">
                        {t('changePasswordForm.newPassword')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        name='newPassword'
                        id='newPasswordInput'
                        required={true}
                        value={this.state.newPassword}
                        onChange={e => {
                          this.setState({ newPassword: e.target.value });
                          e.target.setCustomValidity('');
                        }
                        }
                        type="password"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button form='passwordForm' color="primary">
              {t('ok')}
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </span>);
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section
            className="section-profile-cover section-shaped my-0"
            style={mainSection1}
          >
            <div className="shape shape-style-1 shape-default alpha-4"></div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">{t('profile')}</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        color="primary"
                        onClick={() => this.setState({
                          confrimButtonDisplay: "inline",
                          editProfileButtonDisplay: "none",
                          inputDisable: false
                        })}
                        size="sm"
                        style={{ display: this.state.editProfileButtonDisplay }}
                      >
                        {t('updateButtonLabel')}
                      </Button>
                      <this.ChangePasswordModal />
                      <Button
                        color="warning"
                        onClick={this.onDelete}
                        size="sm"
                        style={{
                          display: this.state.editProfileButtonDisplay,
                          marginLeft: 8
                        }}
                      >
                        {t('deleteButtonLabel')}
                      </Button>
                      <Button
                        form='profileForm'
                        color="success"
                        type='submit'
                        size="sm"
                        style={{ display: this.state.confrimButtonDisplay }}
                      >
                        Confirm
                      </Button>
                    </Col>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Form id="profileForm" onSubmit={this.onSubmit}>
                    <h6 className="heading-small text-muted mb-4">
                      {t('information')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {t('registerForm.name')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="name"
                              value={this.state.name}
                              type="text"
                              onChange={e => this.setState({ name: e.target.value })}
                              disabled={this.state.inputDisable}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {t('registerForm.email')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name='email'
                              required={true}
                              value={this.state.email}
                              onChange={e => this.setState({ email: e.target.value })}
                              type="email"
                              disabled={this.state.inputDisable}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {t('baseForm.username')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name='username'
                              required={true}
                              value={this.state.username}
                              onChange={e => this.setState({ username: e.target.value })}
                              type="text"
                              disabled={this.state.inputDisable}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {t('registerForm.role')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name='role'
                              required={true}
                              value={this.state.role}
                              type="text"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>

            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default withTranslation()(Profile);
