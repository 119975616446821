/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';

// 다국어
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    window.addEventListener('resize', this.updateDimensions);
    window.innerWidth < 992
      ? document.getElementById('lang').classList.replace('nav', 'navbar-nav')
      : document.getElementById('lang').classList.replace('navbar-nav', 'nav');
    // initialise
    headroom.init();
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.state.width < 992
      ? document.getElementById('lang').classList.replace('nav', 'navbar-nav')
      : document.getElementById('lang').classList.replace('navbar-nav', 'nav');
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  state = {
    collapseClasses: '',
    collapseOpen: false,
    width: 0,
    height: 0,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: 'collapsing-out',
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: '',
    });
  };

  onLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');

    window.location.href = "/";
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require('assets/img/brand/logo.png')}
                  style={{ height: '3rem' }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require('assets/img/brand/logo-color.png')}
                          style={{ height: '2.5rem' }}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/notice"
                      id="tooltip333589074"
                      target="_self"
                    >
                      {t('notice')}
                    </NavLink>
                  </NavItem>

                  {localStorage.getItem('isLoggedIn') ? null : (
                    <NavItem>
                      <NavLink
                        className="nav-link-icon"
                        href="/login"
                        id="tooltip333589074"
                        target="_self"
                      >
                        {t('login')}
                      </NavLink>
                    </NavItem>
                  )
                  }
                </Nav>
                {localStorage.getItem('isLoggedIn') ? (
                  <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <span className="nav-link-icon">{t('profile')}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="dropdown-menu-inner-start">
                          <DropdownItem to="/profile" tag={Link}>
                            {t('profile')}
                          </DropdownItem>
                          <DropdownItem onClick={this.onLogout}>
                            {t('logout')}
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                ) : null
                }
                <Nav id="lang">
                  <NavItem>
                    <UncontrolledDropdown>
                      <DropdownToggle caret>
                        {/* <img alt="..." src={require("assets/img/icons/flags/US.png")} /> */}
                        {t('currentLanguage')}
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: '7.5rem' }}>
                        <DropdownItem
                          onClick={() => {
                            localStorage.setItem('lang', 'ko');
                            i18next.changeLanguage('ko');
                          }}
                        >
                          <img
                            alt="..."
                            src={require('assets/img/icons/common/korea.png')}
                            style={{ width: '1.5rem', marginRight: '0.75rem' }}
                          />
                          한국어
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            localStorage.setItem('lang', 'en');
                            i18next.changeLanguage('en');
                          }}
                        >
                          <img
                            alt="..."
                            src={require('assets/img/icons/common/usa.png')}
                            style={{ width: '1.5rem', marginRight: '0.75rem' }}
                          />
                          English
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withTranslation()(DemoNavbar);
